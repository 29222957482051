<template>
	<div class="pageBox">
    <div class="content">
      <el-radio-group v-model="tabPosition" text-color="#E7DCAE" size="medium"  @change="handleClick" style="margin-bottom:30px; height:50px;">
        <el-radio-button label="survey">机型测试概况</el-radio-button>
        <el-radio-button label="compatible" v-if="stateList.compatibleStandard">
          兼容性测试
          <img v-if="stateData.compatible==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.compatible==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/>
        </el-radio-button>
        <el-radio-button label="stable" v-if="stateList.stableStandard">
          稳定性测试
          <img v-if="stateData.stable==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.stable==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/>
          </el-radio-button>
        <el-radio-button label="security" v-if="stateList.securityStandard">
          安全测试
          <img v-if="stateData.security==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.security==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/></el-radio-button>
        <el-radio-button label="consumption" v-if="stateList.consumptionStandard">
          功耗测试
          <img v-if="stateData.consumption==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.consumption==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/></el-radio-button>
        <el-radio-button label="performance" v-if="stateList.performanceStandard">
          性能测试
          <img v-if="stateData.performance==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.performance==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/>
          </el-radio-button>
          <el-radio-button label="large" v-if="stateList.largeStandard">
          大屏标准
          <img v-if="stateData.large==2" class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          <img  v-if="stateData.large==3" class="svg-review" src="@/assets/img/console/failed.png" alt=""/>
          </el-radio-button>
          <el-radio-button v-if="appInfo.lastReviewTime >= privacyTime" label="privacy">
            隐私测试
            <img class="svg-review" src="@/assets/img/console/adopt.png" alt=""/>
          </el-radio-button>
      </el-radio-group>
      <div>
        <appInfoDetails :appInfo="appInfo" v-if="tabPosition=='survey'"></appInfoDetails>
        <appStandardResults v-else-if="tabPosition =='compatible'||tabPosition =='stable'||tabPosition =='security'||tabPosition =='consumption'||tabPosition =='performance'||tabPosition =='large'" :standard = tabPosition></appStandardResults>
        <appPrivacyResults v-else-if="tabPosition =='privacy'"></appPrivacyResults>
      </div>
    </div>
	</div>
</template>

<script>
import { singleGoldIdentificationSever } from "@/common/api-config";
import appInfoDetails from "./componet/appInfoDetails"
import appStandardResults from './componet/appStandardResults';
import appPrivacyResults from './componet/appPrivacyResults';
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: to.query.breadcrumbName, path: to.fullPath} );
    next()
  },
	components: {appInfoDetails,appStandardResults,appPrivacyResults},
	data(){
    return{
      id:this.$route.query.id,
      tabPosition:"survey",
      stateData:{},
      stateList:{},
      appInfo:{},//应用信息
      privacyTime: new Date('2024-06-17 12:00:00').getTime() //隐私标准是否显示判断标准
    }
  },
  created(){
    this.queryApkDetail()
  },
  mounted(){
    this.$store.commit('breadcrumbStore/setBreadcrumb',[
      {name:'金标认证',path:'/console/goldIdentification/list'},
      {name:this.$route.query.apkName+'-版本认证记录',path:'/console/goldIdentification/appVersionList'},
      {name:this.$route.query.apkName+'-认证报告',path:''},
    ]);
    this.queryIdentifyList();
  },
  methods: {
    queryApkDetail(){
      singleGoldIdentificationSever.queryApkDetail({
        id: this.$route.query.id
      }).then(res=>{
        if (res.code == 0){
          this.appInfo=res.data;
        }
      })
    },
    handleClick(value) {
      this.tabPosition = value;
    }, 
    queryIdentifyList() {
      singleGoldIdentificationSever.queryStandardState({id:this.id}).then(res => {
        if (res.code == 0) {
          this.stateData=res.data;
          this.queryShowStandardByIdentifyId();
        } else {      
          this.$message.error(res.message);
        }
      });
    },
    queryShowStandardByIdentifyId(){
      singleGoldIdentificationSever.queryShowStandardByIdentifyId({identifyId:this.stateData.identifyId}).then(res => {
        if (res.code == 0) {
          this.stateList=res.data
        } else {      
          this.$message.error(res.message);
        }
      });
    },
  },

 
  beforeRouteLeave(to, from, next){
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 
    if(to.name === 'appVersionList') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false;
    }
    //去下一页 缓存当前 在去下一页
    if(to.name === 'reportDetails') {
      from.meta.keepAlive = true;
    }
    next();
  }
}
</script>

<style scoped lang="scss">
.pageBox{
	padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  /deep/ .el-radio-button {
    height: 40px;
    span{
      height: 40px;
      line-height: 20px;
      font-size:15px ;
      display: flex;
      align-items: center;
    }
  }
	.content{
		background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    overflow: hidden;
		padding: 28px 40px 20px;
    .svg-review{
      margin-left: 5px;
      width: 16px;
      height: 16px;
      
    }
	}
}
</style>
