<template>
  <div class="appInfoBox">
    <div class="appInfo">
      <div class="imgBox">
        <el-image class="screenshot" :src="appInfo.apkIcon">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="textWrap">
        <h1>{{appInfo.apkName}}</h1>
        <div class="textBox">
          <div>
            <p><b>应用包名</b>{{appInfo.apkPackage}}</p>
            <p>
              <b>认证结果</b>
              <span class="results" :class="{underWay:appInfo.state==2,fail:appInfo.state==3,}">{{appInfo.state| goldIdentificationState()}}</span>
            </p>
          </div>
          <div>
            <p><b>申请时间</b>{{appInfo.createTime| parseTime()}}</p>
            <p><b>完成时间</b>
              <span v-if="appInfo.lastReviewTime">{{appInfo.lastReviewTime| parseTime()}}</span>
              <span v-else>/</span>
            </p>
          </div>
          <div>
            <p><b>测试版本</b>{{appInfo.versionName}}({{ appInfo.versionCode}})</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hint"><span class="red">注：</span>安全标准与机型无关，具体结果请查看“安全测试”栏</div>
    <el-table
      class="tableBox"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      stripe
    >
      <el-table-column label="测试方" prop="memberName" align="center" width="90"></el-table-column>
      <el-table-column label="测试机型" prop="device" align="center" width="120"></el-table-column>
      <el-table-column label="测试结果" prop="state" align="center" width="90">
        <template slot-scope="scope">
          <svg-icon class="svg-review" :svg-name="scope.row.state | versionsTask_memberState()"></svg-icon>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in standardNameList" :key="index" :label="item">
        <template slot-scope="scope" >
          <template v-for="(item2,index2) in scope.row.failCode[index]">
            <span :key="index2" class="red cursor" v-if="item2.termType==1">
              <i v-if="index2">、</i><span @click="onTestDetails(item2.termCode,scope.row.memberId)">{{item2.termCode}}</span>
            </span> 
          </template>
          <br>
          <template v-for="(item2,index2) in scope.row.failCode[index]">   
            <span :key="index2" class="warning cursor" v-if="item2.termType==2">
              <span @click="onTestDetails(item2.termCode,scope.row.memberId)">{{item2.termCode}}<i v-if="index2!=scope.row.failCode[index].length-1">、</i></span>
            </span>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer margin-t30">
      <div class="tips">
        <span class="red">红色测试编号：</span>测试未通过项，会导致测试结果不通过，需要开发者优化。<br/>
        <span class="warning">橙色测试编号：</span>测试警告项，不会影响测试结果，建议开发者优化。
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.perPage"
        :current-page="formData.page"
        layout="total, prev, pager, next, sizes"
        :total="total">
      </el-pagination>
    </div>
    <test-item-details v-if="isShowlModal" :modalShow="isShowlModal" :form="form" @closeModal="closeModal()" ></test-item-details>
  </div>
</template>

<script>
import { singleGoldIdentificationSever } from "@/common/api-config";
import testItemDetails from "../template/testItemDetails";
export default {
   components: { testItemDetails },
  props: {
    appInfo:{
      type:Object,
      require:true
    }
  },
  data(){
    return{
    isShowlModal:false,
    form:{},
    tableData:[],
    standardNameList:[],
     formData: {
        page: 1,
        perPage: 10,
        id:this.$route.query.id,
      },
      total:0,
    }
  },
  created(){
    this.queryApkDeviceList();
  },
  methods:{
    queryApkDeviceList(){
      singleGoldIdentificationSever.queryApkDeviceList(this.formData).then(res=>{
        if (res.code == 0){
          this.standardNameList = res.data.data.itemNameList;
          this.tableData=res.data.data.list;
          this.total=res.data.total
        }
      })
    },

    onTestDetails(value,memberId,){
       this.form={
        id:this.$route.query.id,
        code:value,
        memberId:memberId,
      }
      this.isShowlModal = true;
    },

    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },

    //table点击更改一页多少行
    handleSizeChange(val) {
      this.formData.perPage = val;
      this.queryApkDeviceList();
    },
    
    //table点击修改页码
    handleCurrentChange(val) {
      this.formData.page = val;
      this.queryApkDeviceList();
    },
  },
 
}
</script>

<style scoped lang="scss">
.appInfoBox{
  .appInfo{
    display: flex;
    justify-content: flex-start;
    align-items: top;
    margin-bottom: 20px;
    .imgBox{
      width: 100px;
      height: 100px;
      margin-right: 19px;
      .screenshot{
        border-radius: 5px;
      }
      .el-icon-picture-outline{
        font-size: 90px;
        margin-top: -5px;
      }
    }
    .textWrap{
      h1{
        font-size: 24px;
      }
      .textBox{
        width: 800px;
        display: flex;
        div{
          margin-right: 60px;
          p{ 
            margin-top: 10px;
            color: #666;
            b{
              color: #333333;
              margin-right: 10px;
            }
            .results{
              font-weight: bold;
            }
            .underWay{            
              color: #428F1E;
            }
            .fail{
              color: #CF2233;  
            }
          }
        }
      }
    }
  }
  .hint{
    margin-bottom: 10px;
    font-size: 15px;
  }
  .svg-review{
    width: 30px;
    height: 30px;
  }
  .footer{
    display: flex;
    justify-content: space-between;
    .tips{
      color: #666;
      font-size: 14px;
    }
  }
}

</style>
