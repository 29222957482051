<template>
  <div>
    <el-table
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      :span-method="objectSpanMethod"
      class="tableBox"
    >
      <el-table-column label="测试项" prop="title" width="100"></el-table-column>
      <el-table-column label="测试编号" prop="code"  width="100">
      </el-table-column>
      <el-table-column label="测试标准" prop="name" width="450"  v-if="standard!='security'">
        <template slot-scope="scope">
          <div style="text-align: left;">
            {{scope.row.name}}
          </div>
        </template>   
      </el-table-column>
      <el-table-column label="测试标准" prop="name" width="900" v-if="standard =='security'" >
        <template slot-scope="scope">
          <div style="text-align: left;">
            {{scope.row.name}}
          </div>
        </template>   
      </el-table-column>
      <el-table-column label="测试结果" width="90" prop="state">
        <template slot-scope="scope">
          <svg-icon v-if="scope.row.termType==1" class="svg-review" :svg-name="scope.row.state | versionsTask_memberState()"></svg-icon>
          <svg-icon v-if="scope.row.termType==2" class="svg-review" :svg-name="scope.row.state | warnVersionsTask_memberState()"></svg-icon>
        </template>    
      </el-table-column>
      <el-table-column label="未通过机型展示" width="480" v-if="standard!='security'">
          <template slot-scope="scope">
            <template v-for="(item,index) in scope.row.failDevice">
              <div class="deviceBox" :key="index">
                <div v-if="!form.memberId" class="memberBox"><p class="memberName">{{item.memberName}}</p><p>：</p></div>
                <div class="device">
                  <span v-for="(item2,index2) in item.deviceList" :key="index2">
                  {{item2.device}}<span v-if="item2.value">【&nbsp;{{item2.value}}&nbsp;】</span><i v-if="index2!=item.deviceList.length-1">、</i>
                  </span>
                </div> 
              </div>              
            </template>            
        </template> 
      </el-table-column>
      <el-table-column label="测试方法" >
        <template slot-scope="scope" >
          <el-button v-if="scope.row.isShow==1" class="color-blue link-button" @click="onTestMethod(scope.row)"  type="text" >
            查看
          </el-button>
          <p v-if="scope.row.isShow==2">/</p>
        </template>
       
      </el-table-column>
      <el-table-column v-if="standard!='security'" label="详情">
        <template slot-scope="scope">
          <el-button class="color-blue link-button" type="text" @click="openDetail(scope.row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <test-method ref="modalRef"></test-method>
  </div>
</template>

<script>
import testMethod from '../template/testMethod';
import { singleGoldIdentificationSever } from "@/common/api-config";
export default {
  props: {
    standard: {
      type: String,
      required: true
    }
  },
  components: {testMethod},
  data(){
    return{
      isShowlModal:false,
      form:{
        id:this.$route.query.id,
        code:this.standard,
      },
      tableData: [],
      memberList:[],
      data:{}
    }
  },
  created(){
    this.getTableData();
  },
  methods:{
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    }, 
    getTableData(){
      singleGoldIdentificationSever.queryReport(this.form).then(res=>{
        if (res['code'] == 0 && res.data) {
          this.tableData = res.data;
        }
      })
    },
    //查看详情
    openDetail(data){
      var formData={
        id:this.$route.query.id,
        code:data.code,
        standard:this.standard,
        title:data.title,
      }
       this.$router.push({
        path:'reportDetails',
        query:{
          formData: encodeURIComponent(JSON.stringify(formData)),
          breadcrumbName:this.standardCode_to_standardName(this.standard),
        }
      })
    },
    
    standardCode_to_standardName(code){
      let text='';
      switch (code) {
        case 'compatible':
          text = "兼容性测试"
          break;
        case 'stable':
          text = "稳定性测试"
          break;
        case 'security':
          text = "安全测试"
          break;
        case 'consumption':
          text = "功耗测试"
          break;
        case 'performance':
          text = "性能测试"
          break;
        case 'large':
          text = "大屏标准"
        break;
      }
      return text; 
    },

    onTestMethod(data) {
      this.$refs.modalRef.show(data);
    },
    mergeCol(id, rowIndex) { // 合并单元格
    // id：属性名
    // rowIndex：行索引值
      var idName = this.tableData[rowIndex][id] // 获取当前单元格的值
      if (rowIndex > 0) { // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.tableData[rowIndex][id] != this.tableData[rowIndex - 1][id]) { // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex; var num = 0 // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
            if (this.tableData[i][id] === idName) { // 判断循环的单元格的值是不是和当前行的值相等
              i++ // 如果相等，则索引值加1
              num++ // 合并的num计数加1
            } else {
              i = this.tableData.length // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1
          }
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1
          }
        }
      } else { // 如果是第一行，则直接返回
        let i = rowIndex; let num = 0
        while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
          if (this.tableData[i][id] === idName) {
            i++
            num++
          } else {
            i = this.tableData.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },

    // 这个方法是 element-ui提供的单元格合并方法
    // objectSpanMethod 传入了 { row, column, rowIndex, columnIndex }
    // row: 当前行
    // column: 当前列
    // rowIndex：当前行号
    // columnIndex ：当前列号
    // 1代表：独占一行
    // 更大的自然数：代表合并了若干行
    // 0：代表“消失”的哪那一个单元格，后面的单元格向前推一格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) { // 合并单元格
      switch (columnIndex) { // 将列索引作为判断值
      // 通过传递不同的列索引和需要合并的属性名，可以实现不同列的合并
        case 0:
          return this.mergeCol('title', rowIndex)
      }
    },
  },
  watch:{
    standard(){
      this.form.code=this.standard;
      this.getTableData();
    },
  },
}
</script>

<style scoped lang="scss">
.svg-review{
  width: 30px;
  height: 30px;
}
.deviceBox{
  display: flex;
  text-align: left; 
  .memberBox{
    display: flex;
    .memberName{
      width: 42px;
    }
  } 
}
</style>


