<template>
  <div>
    <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        class="tableBox"
    >
      <el-table-column label="测试厂商" prop="memberName"></el-table-column>
      <el-table-column label="测试结果" prop="state">
        <template slot-scope="scope">
          <svg-icon class="svg-review" :svg-name="scope.row.state | versionsTask_memberState()"></svg-icon>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t30">
      <b>隐私检测项详见附件：</b>
      <el-button size="small" type="primary" @click="handleDownload">下载附件</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          memberName: 'OPPO',
          state: '2'
        },
        {
          memberName: 'vivo',
          state: '2'
        },
        {
          memberName: '小米',
          state: '2'
        },
      ]
    }
  },
  methods: {
    handleDownload() {
      window.location.href = 'https://statres.itgsa.com/20240613/ITGSA——OPPO、vivo、小米隐私测试项.xlsx';
    }
  }
}
</script>

<style scoped lang="scss">
.svg-review {
  width: 30px;
  height: 30px;
}
</style>


